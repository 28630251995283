import {
  MonitoringErrorBoundary,
  MonitoringManager,
} from "@flash-tecnologia/hros-web-utility";
import { MonitoringLevel } from "@flash-tecnologia/hros-web-utility/dist/monitoring/MonitoringLevel";

export const projectDsn =
  "https://9c8d66e02efe710b827f62c2dad43a5e@o266934.ingest.sentry.io/4506020523016192";

export class ErrorBoundary extends MonitoringErrorBoundary {
  project: string = projectDsn;

  constructor(props) {
    super(props);
  }

  static captureException(
    error: Error,
    severity?: MonitoringLevel,
    extras?: Record<string, any>
  ) {
    MonitoringManager.captureException(projectDsn, error, severity, extras);
  }
  componentDidCatch(error: any) {
    MonitoringManager.captureException(this.project, error);
  }
}
