import { OnboardingStep } from "./types";

export const onboardingStepsList: OnboardingStep[] = [
  {
    id: "KNOW_THE_PLATFORM",
    icon: "IconBrowser",
    title: "Conheça a plataforma",
    path: "/home?onboarding=true",
  },
  {
    id: "KNOW_FLASH_EXPANSE",
    icon: "IconCards",
    title: "Conhecer Gestão de Despesas",
    path: "/corporateCard/home",
  },
  {
    id: "KNOW_FLASH_PEOPLE",
    icon: "IconCards",
    title: "Conhecer Gestão de Pessoas",
    path: "/flows/hiring",
  },
  {
    id: "REGISTER_EMPLOYEE",
    icon: "IconUsers",
    title: "Cadastrar pessoas",
    path: "/employees",
  },
  {
    id: "CONFIGURE_YOUR_COMPANY",
    icon: "IconEdit",
    title: "Configurar a sua empresa",
    path: "/settings",
  },
  {
    id: "ADD_MORE_COMPANIES",
    icon: "IconBuildingSkyscraper",
    title: "Adicionar outras empresas",
    path: "/benefits/dashboard/company/register",
    requirements: ["CONFIGURE_YOUR_COMPANY"],
    requirementReason: "Configure sua empresa para desbloquear esta etapa.",
    blocked: true,
  },
  {
    id: "MAKE_FIRST_BENEFITS_REQUEST",
    icon: "IconWallet",
    title: "Fazer pedido de benefícios",
    path: "/benefits/dashboard/company/employees",
    requirements: ["REGISTER_EMPLOYEE"],
    requirementReason:
      "Cadastre pessoas e atribua benefícios para desbloquear esta etapa.",
    blocked: true,
  },
  {
    id: "ASK_FOR_EMPLOYEES_CARDS",
    icon: "IconCreditCard",
    title: "Pedir cartões",
    path: "/cards",
    requirements: ["REGISTER_EMPLOYEE"],
    requirementReason: "Cadastre pessoas para desbloquear esta etapa",
    blocked: true,
  },
  {
    id: "ADD_LEADER_AND_LED",
    icon: "IconUsers",
    title: "Adicione líder e liderado",
    path: "/orgchart",
    requirements: ["KNOW_FLASH_PEOPLE"],
    requirementReason:
      "Cadastre pessoas e finalize a contratação do módulo de People para desbloquear esta etapa.",
    blocked: true,
  },
];
