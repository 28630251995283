import { useCallback, useEffect } from "react";
import create from "zustand";
import { useShallow } from "zustand/react/shallow";
import { OnboardingStep, OnboardingStepIds } from "./types";
import { onboardingStepsList } from "./onboarding-steps-list";
import { completeOnboardingStep, getOnboardingSteps } from "../../api";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

type OnboardingStore = {
  steps: OnboardingStep[];
  loading: boolean;
  onboardingProgressPercentage: number;
  isInitialized: boolean;
  companyId: string;
  completeStep: (stepId: OnboardingStepIds) => void;
  initialize: (state: any) => void;
};

const calculateOnboardingProgressPercentage = (steps: OnboardingStep[]) => {
  const stepsCompletedCount = steps.filter((step) => step.done).length || 0;
  return Math.round((stepsCompletedCount / steps.length || 0) * 100);
};

const useOnboardingStepsStore = create<OnboardingStore>((set, getState) => ({
  steps: onboardingStepsList,
  loading: false,
  isInitialized: false,
  companyId: "",
  onboardingProgressPercentage: 0,
  completeStep: async (stepId) => {
    const { steps: currentSteps, companyId } = getState();
    await completeOnboardingStep(companyId, stepId);

    const updatedSteps = currentSteps.map((step) => {
      if (step.id === stepId) {
        step.done = true;
      }
      if (step.blocked) {
        step.blocked = step.requirements?.some(
          (stepId) => !currentSteps.find(({ id }) => id === stepId).done
        );
      }
      return step;
    });

    set(() => ({
      steps: updatedSteps,
      onboardingProgressPercentage:
        calculateOnboardingProgressPercentage(updatedSteps),
    }));
  },
  initialize: async (companyId) => {
    set(() => ({ loading: true, isInitialized: true }));
    const { stepsCompleted } = await getOnboardingSteps(companyId);

    const updatedSteps = onboardingStepsList.map((step) => ({
      ...step,
      done: stepsCompleted.includes(step.id),
      blocked: step.blocked
        ? step.requirements?.some((stepId) => !stepsCompleted.includes(stepId))
        : step.blocked,
    }));

    set(() => ({
      steps: updatedSteps,
      onboardingProgressPercentage:
        calculateOnboardingProgressPercentage(updatedSteps),
      loading: false,
      companyId,
    }));
  },
}));

export const useOnboardingSteps = () => {
  const { selectedCompany } = useSelectedCompany();

  const {
    steps,
    onboardingProgressPercentage,
    loading,
    completeStep,
    companyId,
  } = useOnboardingStepsStore(
    useShallow((state) => ({
      steps: state.steps,
      companyId: state.companyId,
      loading: state.loading,
      completeStep: state.completeStep,
      onboardingProgressPercentage: state.onboardingProgressPercentage,
    }))
  );

  useEffect(() => {
    const store = useOnboardingStepsStore.getState();
    const hasCompanyChanged = !!companyId && companyId !== selectedCompany.id;

    if (!store.isInitialized || hasCompanyChanged) {
      store.initialize(selectedCompany.id);
    }
  }, [selectedCompany.id]);

  return {
    steps,
    onboardingProgressPercentage,
    loading,
    completeStep,
  };
};
