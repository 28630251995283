import { useTranslation } from "react-i18next";

export const Translator = ({ path, prefix }) => {
  const { t } = useTranslation();
  const concatPath = prefix ? prefix + "." + path : path;
  return t(concatPath);
};

export const translate = ({ path, prefix }) => {
  return Translator({ prefix, path: path });
};
