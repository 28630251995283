import { OnboardingStepIds } from "@/flash-platform-hros-web-hocs";
import { ErrorBoundary } from "@/utils/ErrorBoundary";
import { Axios } from "@flash-tecnologia/hros-web-utility";

type OnboardingStepsResponse = {
  stepsCompleted: OnboardingStepIds[];
};

export const getOnboardingSteps = async (
  companyId: string
): Promise<OnboardingStepsResponse> => {
  try {
    const response = await Axios({
      method: "get",
      service: "company",
      url: `/onboarding-steps?companyId=${companyId}`,
    });

    return response.data.value;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw error;
  }
};

export const completeOnboardingStep = async (
  companyId: string,
  step: string
): Promise<OnboardingStepsResponse> => {
  try {
    const response = await Axios({
      method: "post",
      service: "company",
      url: "/onboarding-steps/complete-step",
      data: { step, companyId },
    });

    return response.data.value;
  } catch (error) {
    ErrorBoundary.captureException(error);
    throw error;
  }
};

export const getCompany = async (registrationNumber: string) => {
  try {
    const { data } = await Axios({
      service: "company",
      method: "get",
      url: `/companies/registrationNumber/${registrationNumber}`,
    });

    return data?.value;
  } catch (error) {
    ErrorBoundary.captureException(error);
  }
};
