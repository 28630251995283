import {
  getFromLS,
  setInLS,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";
import { getCompany } from "src/api";

export const useCompanyMigrated = () => {
  const { selectedCompany } = useSelectedCompany();
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    async function getCompanyData() {
      setLoading(true);
      const companyData = await getCompany(selectedCompany.registrationNumber);

      if (!!companyData) {
        setCompany(companyData);
        setInLS({
          key: `selectedCompany_${companyData?._id}`,
          value: { id: companyData._id, origin: companyData.origin },
        });
      }

      setLoading(false);
    }

    const companyData = getFromLS(`selectedCompany_${selectedCompany?.id}`);
    if (companyData) {
      setCompany(companyData);
      return;
    }

    if (!company || company?.id !== selectedCompany.id) {
      getCompanyData();
    }
  }, [selectedCompany?.id]);

  const isMigrated = !["hros", "flashos.signup"].includes(company?.origin);

  return {
    isMigrated,
    loading,
  };
};
